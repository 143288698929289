
body{-webkit-tap-highlight-color : transparent;}

.player-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

/*결제창 모바일 애니메이션*/
.scroll-hidden::-webkit-scrollbar {
    display: none;
}
/*full-screen*/
/*.fp-table{*/
/*    justify-content: start!important;*/
/*}*/
.fp-watermark{
    display: none!important;
}
.fp-section{
    /*height: calc(100% - 10px) !important;*/
}
#fp-nav{
    top:95% !important;
}
#fp-nav ul{
    display: flex;
}
#fp-nav ul li{
    margin:3px!important;
}
.fullPge-gap{
    gap: 3rem;
}
@media screen and (max-height:700px){
    .fullPge-gap{
        gap: 0.5rem;
    }
}
/**/
/*드래그 컬러*/
::selection { background:#e2e2e2; }
.pointer{cursor: pointer!important;}
/*로딩 아이콘*/
.lds-ring div {
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
/*브래이즈 팝업*/
.ab-centering-div, .ab-iam-root.v3{
    z-index: 20000000 !important;
}

.animate-width	{
    animation: width 0.5s ease-in;
}


.list-marker .list-marker-in li::before {
    content:'• '
}
.list-marker .list-marker-in{
    text-indent : -9px;
    /* text-indent : -50%; */
    padding-left: 10px;
}

@keyframes width {
    0%, 100% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.list-marker .list-marker-in li::before {
    content:'• '
}

.corporate-hover-in{
    opacity:0;
}

.margin-nth div:nth-child(1){margin-top:40px;}
.margin-nth div:nth-child(3){margin-top:70px;}
.margin-nth div:nth-child(4){margin-top:40px;}
.corporate-hover:hover .corporate-hover-in{
    opacity:50%;
}
.button-hover{
    background: #3e52bb;
}
.button-hover:hover{
    background: #384ba6;
}
.CustomDialog .MuiDialog-paper{overflow:visible!important; max-width: 1500px;}
.CustomDialog{z-index: 999999!important;}
.CustomMui .MuiDialog-paper{box-shadow: none; border-radius: 0px!important;width:100% !important; max-width:100% !important;margin:13px!important;}
.PayPopUp .MuiDialog-paper{box-shadow: none; border-radius: 0px!important; max-width:100% !important;margin:13px!important;padding:18px}
.popupToast .MuiDialog-paper{box-shadow: none; border-radius: 0px!important;width:100% !important;margin:12px!important;}
.popupToast .MuiDialog-container{align-items: start}
.sale_color{color: #e24f49;}
.bg_3e52bb{background:#3e52bb;}
.bd_3e52bb{border-color:#3e52bb;}
.tx_3e52bb{color:#3e52bb;}
.bg_323e99{background: #323e99;}
.tx_3e52bb{color:#3e52bb; }
.tx_FFE500{color:#FFE500; }
.tx_FF0000{color:#FF0000; }
.text-kakao{color: #f5dd0b;}
.EF4444{color: #EF4444;}
.text-color-fe7b1e{color:#fe7b1e!important;}
    /*브랜드 페이지 오버*/
.brand_hover2{visibility: hidden; opacity: 0; transition: 0.2s all;}
.brand_hover:hover .brand_hover2{visibility: visible; opacity: 1; transition: 0.2s all;}
.share_b:hover{fill: #333D4B;}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes anim {
    to {
        transform: rotate(360deg);
    }
}
@keyframes anim {
    to {
        transform: rotate(360deg);
    }
}


/*top scroll button*/

.topBtn {
    position: fixed;
    opacity: 0;
    bottom: 135px;
    right: 31px;
    z-index: 9999;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 0 none;
    background: #3E52BB;
    letter-spacing: -0.06em;
    cursor: pointer;
    transition: opacity 0.3s ease-in;
    box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.4);
}
.topBtn path{stroke:#fff; transition: 0.3s all;}
.topBtn.active {
    z-index: 10;
    opacity: 1;
}
.PC_BF_NAV{
    -webkit-animation: colorChange 1s ease-in-out infinite;
    animation: colorChange 1s ease-in-out infinite;
    animation-direction: alternate;
}
.BF_NAV .mtn_hover{
    -webkit-animation: colorChange 1s ease-in-out infinite;
    animation: colorChange 1s ease-in-out infinite;
    animation-direction: alternate;
}
@-webkit-keyframes colorChange {
    0% {
        color: #999999;
    }
    100% {
        color: rgb(185 28 28);
    }
}
@keyframes colorChange {
    0% {
        color: #999999;
    }
    100% {
        color: rgb(185 28 28);
    }
}
.topBtn:hover,
.topBtn:focus,
.topBtn:active {
    outline: 0 none;
}

.select-arrow-none{
    -webkit-appearance:none!important;
    -moz-appearance:none!important;
    appearance:none!important;
    background-repeat: no-repeat!important;
    background-position: right 60px!important;
}

/**/
.inspection_h{height: calc(100vh - 80px)}
/*pdpage*/



.pd_page .MuiAccordionSummary-expandIconWrapper{
    width:15px !important;
}
.pd_page .css-1betqn-MuiAccordionSummary-content .summary_t{
    font-weight: 400;
    transition:0.2s all;
}
.pd_page .Mui-expanded .summary_t{
    font-weight: 600;
    transition:0.2s all;
}
.pd_page .css-1betqn-MuiAccordionSummary-content .summary{
    position:absolute;
    bottom:0;
    left:0;
    border-bottom:1px solid #000;
    width:0px;
    transition:0.2s all;
}
.pd_page .css-15v22id-MuiAccordionDetails-root{
    padding:0 !important;
}
.pd_page .Mui-expanded .summary{
    position:absolute;
    bottom:0;
    left:0;
    border-bottom:1px solid #000;
    width:100%;
    transition:0.2s all;
}
/**/
.buy_ani {
    animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1);
}
@keyframes pulse {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/**/
/*backdrop*/
.pop_backdrop{backdrop-filter: blur(12px);}
.pop_width{width: 101%;}

.color333537E6{color:#333537E6;}
.bgcolorf5f5f5{background: #f5f5f5; transition: 0.5s all}
.faq_hover:hover{background:#e9e9e9; transition: 0.5s all}

/*탑배너*/
.sticky_rec{
    position: -webkit-sticky;
    position: sticky;
    top:50px;
}
.stroke_white path{stroke: #fff;}
.fill-black path{fill: #363636; stroke-width: 0.5px; stroke: #363636;}
.fill-white path{fill: gray; stroke-width: 0.5px; stroke: gray;}
.min_width60{min-width: 60px;}
.z_1000{z-index: 1000;}
.z_1001{z-index: 1001;}
.h_90{height: 88%;}
.max_width_s{max-width: 780px;}
.top_banner:hover .top_banner_con{
    opacity: 1;
    transition: 0.5s all;
    background:rgba(45, 45, 45, 0.5);
}
.top_banner_con{
    opacity: 0;
    transition: 0.5s all;
    background:rgba(45, 45, 45, 0.0);
}
.grid_8{grid-template-rows: repeat(8, minmax(0, 1fr));}
.top_banner_b Button{transition: 0.5s all;}
.top_banner_b Button:hover{background: #323e99;transition: 0.5s all;}
.tn_hover{transition: 0.2s all; color: #999999;}
.tn_hover:hover{color: #fff;transition: 0.2s all;}
.tn_hoveri{transition: 0.2s all;}
.tn_hoveri:hover{background: #212121;transition: 0.2s all;}
.mainStore .mainStoreText{opacity:0;}
.mainStore:hover .mainStoreText{opacity:1;}
.mainContents1 .mainContentsHover2{display: none;}
.mainContents2 .mainContentsHover1{display: none;}
.main_photocard>img{transition: 0.5s all;}
.main_photocard>img:hover{
    transition: 0.5s all;
    filter: brightness(70%);
}
.main_photocard h1{opacity: 0; transition: 0.3s all;}
.main_photocard h1 div{position: absolute;top: 4%;margin:0 auto;width: 100%;}
.main_photocard h1 p{display: inline;letter-spacing: 0.5px;}
.main_photocard h1:hover{opacity: 1;  transition: 0.3s all;}
/*dot-----------------------*/
.medium-dot{
    text-indent: -9px;
}
.medium-dot::before {
    content: "●";
    font-size: 6px;
    vertical-align: top;
    margin-right: 4px;
}
.start-dot{
    text-indent: -9px;
    color: #FE0000;
}
.start-dot::before {
    content: "*";
    vertical-align: middle;
    margin-right: 4px;
}
/**/
.t_navbar{
    background: #363636;
    color: #fff;
    font-size: 12px;
    cursor: default;
    padding: 20px 15px;
}
.raffle_da{
    text-transform: lowercase !important;
}
.raffle_da:first-line{
    text-transform: capitalize !important;
}
.t_navbar .t_link{
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
}
.t_navbar .t_link:hover{
    color: #e2582c;
    transition: 0.5s all;
}
.sic_2_l{
    float: left;
    border:1px solid #d8d8d8;
    position: relative;
    border-left: none;
}
.sic_2_r{
    float: left;
    border:1px solid #d8d8d8;
    position: relative;
    border-left: none;
    overflow: hidden;
}
.MuiIconButton-edgeEnd{display: none;}
.sic_2>div:hover p{opacity: 1;}
.sic_2 p{
    position: absolute;
    z-index: 1;
    color: #fff;
    top: 15px;
    left: 15px;
    font-size: 17px;
    font-weight: 700;
    opacity: 0;
}
.sic_2>div img{width: 100%;}
.sic_2_txt{
    padding-top: 24px;
    height: 400px;
    padding-left: 20px;
    position: relative;
    width: 330px;
}
.sic_2_txt h2{
    font-size: 12px;
    line-height: 1.3rem;
    color: #333537e6;
    font-weight: 500;
}
.sic_2_txt h3{
    font-size: 12px;
    font-weight: 200;
    line-height: 1.2;
}
.sic_2_txt h3 span{float: right;}
.sic_2_txt button:first-child{float:left;margin-right: 10px;}
.sic_2_txt .o_button{
    position: absolute;
    right: 0;
    top:30px;
}
.sic_3 .sic_3_l{
    width: 50%;
}
.sic_3 .sic_3_l p{
    background: #2d2e2f;
    padding:30px 0 30px 20px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
}
.gnb{
    width:100%;
    margin:0 auto;
}
.gnb>li>a{   /*주메뉴 */
    display:block;
    height:60px;
    line-height:60px;
    font-size:13px;
    font-weight:bold;
    padding:5px 20px;
    border-top:1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}

.gnb>li>ul{display:none;}/*서브메뉴 숨김*/
.gnb>li>ul>li{
    height:70px;
    border-right: 1px solid #d8d8d8;
    border-top:1px solid #d8d8d8;
    line-height: 70px;
    background:#f5f5f5;
}
.gnb>li>ul>li>a{  /*서브메뉴 */
    display:block;
    font-size:12px;
    color:#000;
    padding:0 20px;
}
#st_click{position: relative;}
#st_click img{
    width: 20px;
    display: block;
    right: 20px;
    top: 25px;
    position: absolute;
}
.mclose{
    width: 20px;
    right: 20px;
    top: 20px;
    position: absolute;
    transform: rotate(0.5turn);
}
/*스탁 키스트*/
.stock_1{
    background:#2d2d2f;
    color:#fff;
    font-weight: 300;
    font-size:13px;
    padding:10px 15px;
}
.stock_2{
    font-weight: 300;
    font-size: 13px;
    padding-left: 15px;
}
.stock_3{
    font-size: 13px;
}
/*디테일 페이지*/
.sic_2_l_d{width: 100%;}
.sic_2_l_d img{width: 50%;display: inline-block;float: left;}
.sic_2_txt_d{
    float: left;
    width: 330px;
}
.sic_border{
    border-bottom: 1px solid #d8d8d8;
    width: 50%;
    float: right;
    margin-top:11%;
}
.od_50 img{width: 50%;float: right;margin-top:150px;}
.od_100 img{width: 50%; float: left; margin-top:150px;}
.od_1001 img{width: 90%; float: left; margin-top:150px;}
.od_150 img{width: 80%; float: right; margin-top:150px;}
.od_1501 img{width: 90%; float: right; margin-top:150px;}
.od_55 img{width: 60%;float: left;margin-top:150px;}

.outlet_stock{
    width: 50%;
    float: left;
}
@media screen and (max-width:1500px) {
    .sic_border {
        border-bottom: 1px solid #d8d8d8;
        width: 50%;
        float: right;
        margin-top: 4%;
    }
}
@media screen and (max-width:1100px){
    .topBtn {
        position: fixed;
        opacity: 0;
        bottom: 135px;
        right: 12px;
        z-index: 9999;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 0 none;
        background: #3E52BB;
        letter-spacing: -0.06em;
        cursor: pointer;
        transition: opacity 0.3s ease-in;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.4);
    }
    .sic_2_l{
        width: 100%;
        border:1px solid #d8d8d8;
        position: relative;
        border-left: none;
    }
    .sic_2_r{
        width: 100%;
        border:1px solid #d8d8d8;
        position: relative;
        border-left: none;
        overflow: hidden;
    }
    .sic_2_l_d img{width: 100%;}
    .sic_2_txt_d{
        width: 430px;
    }
    .outlet_stock{
        width: 100%;
        float: left;
        display: block;
    }
    .sic_2_txt_d{
        float: left;
        width: 330px;
        margin-left: 0;
    }
    .sic_border{
        border-bottom: 1px solid #d8d8d8;
        width: 100%;
        margin-top:7%;
    }
    .first_od{width: 100%; float: right; margin-top:-150px;}
}
@media screen and (max-width:950px){
    .popupToast .MuiDialog-paper{box-shadow: none; border-radius: 0px!important;width:100% !important;margin:60px 0!important;}
    .swiper-pagination-bullets{
        position:absolute !important;
        bottom:150px !important;
    }
    .PayPopUp .MuiDialog-paper{box-shadow: none; border-radius: 0px!important; max-width:100% !important;margin:13px!important;padding:10px}
}
@media screen and (max-width:768px){
    .swiper-pagination-bullets{
        position:absolute !important;
        bottom:190px !important;
    }
}
@media screen and (max-width:750px){
    .swiper-pagination-bullets{
        position:absolute !important;
        bottom:20px !important;
    }
}
@media screen and (max-width:500px){
    .sic_2_l_d img{width: 100%;}
    .sic_2_txt_d{
        width: 430px;
    }
    .sic_2_txt{
        height: 350px;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        width: 100%;
    }
    .sic_2_txt .o_button{
        position: absolute;
        right: 10px;
    }
    .first_od{width: 100%; float: right; margin-top:-50px;}
    .od_50 img{width: 100%;float: right;margin-top:50px;}
    .od_100 img{width: 100%; float: left; margin-top:50px;}
    .od_1001 img{width: 100%; float: left; margin-top:50px;}
    .od_150 img{width: 100%; float: right; margin-top:50px;}
    .od_1501 img{width: 100%; float: right; margin-top:50px;}
    .od_55 img{width: 100%;float: left;margin-top:50px;}
}

/*min height*/
@media screen and (max-height:400px){
    .max_height_mq{display:none;}
}
.left-blur{
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.5) 10%,
            rgba(255, 255, 255, 0.8) 30%,
            rgba(255, 255, 255, 0.95) 70%,
            rgba(255, 255, 255, 1) 100%
    );
}