@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/PretendardVariable.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+2000-206F, U+3000-303F, U+AC00-D7A3;
}

@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/PretendardVariable.ttf') format('truetype');
  font-weight: bold;
  unicode-range: U+0000-00FF, U+2000-206F, U+3000-303F, U+AC00-D7A3;
}

@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/PretendardVariable.ttf') format('truetype');
  font-weight: 500;
  unicode-range: U+0000-00FF, U+2000-206F, U+3000-303F, U+AC00-D7A3;
}

@font-face {
  font-family: 'Karla';
  src: url('assets/font/Karla-VariableFont_wght.ttf') format('truetype');
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: 'Karla';
  src: url('assets/font/Karla-VariableFont_wght.ttf') format('truetype');
  font-weight: bold;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: 'Karla';
  src: url('assets/font/Karla-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  unicode-range: U+0041-005A, U+0061-007A;
}

@layer base {
  body {
    @apply text-gray-800;
    touch-action: pan-y;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }
}

h1 {
  @apply text-3xl font-bold;
}
h2 {
  @apply text-2xl font-bold;
}
h3 {
  @apply font-semibold;
}
h4 {
  @apply text-xl font-bold;
}
pre{
  @apply max-w-3xl m-auto text-13 leading-6 px-4;
}
pre{
  margin:0 auto !important;
  font-family: 'Pretendard', 'Karla', sans-serif;
}
@layer components {
  .avatar {
    @apply inline-block object-cover rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .button {
    @apply px-4 h-12 text-sm font-normal rounded-md disabled:bg-brand-2 disabled:text-gray-300;
  }
  .card {
    @apply flex flex-col rounded-lg border border-gray-200 divide-y divide-gray-200 overflow-hidden shadow;
  }
  .checkbox {
    @apply wh-4 rounded text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-2 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }
  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }
  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-medium;
  }
  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .select {
    @apply pl-0 py-0 pr-4 h-12 border-0 text-14 rounded-md placeholder-gray-400
    disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .select-default {
    @apply px-4 h-12 border border-gray-400 rounded-md placeholder-gray-500
    disabled:bg-gray-100 disabled:text-gray-400 disabled:opacity-100 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-md text-sm font-medium flex items-center;
  }
  .table {
    @apply divide-gray-200 text-12;
  }
  .table-head {
    @apply bg-brand-1;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-4 text-center font-normal text-white uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-center font-normal text-brand-1 truncate;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .textfield {
    @apply px-4 h-12 border border-gray-400 rounded-md placeholder-gray-500
           disabled:bg-gray-100 disabled:text-gray-400 disabled:opacity-100 focus:border-brand-1 focus:ring-0;
  }
  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }
  .h1 {
    @apply text-36;
  }

  .h2 {
    @apply md:text-30 text-20;
  }

  .h3 {
    @apply md:text-24 text-20;
  }

  .h4 {
    @apply text-20;
  }

  .h5 {
    @apply text-18 font-bold;
  }

  .h6 {
    @apply text-14 font-bold;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  font-family: 'Pretendard', 'Karla', sans-serif;
}

.slick-slide.slick-active div {
  margin-left: auto;
  margin-right: auto;
}
#journal .fr-view p {
  padding: 0 1.5rem !important;
  text-align: center;
  width: 100%;
}
#journal .fr-view iframe{
  display: flex !important;
  justify-content: center !important;
}
#journal .fr-view .fr-video{
  display: flex !important;
  justify-content: center !important;
}
#journal .fr-view p img {
  /*min-width: -webkit-fill-available !important;*/
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

/* 디거깅 모드 시 에러 숨김처리*/
iframe#webpack-dev-server-client-overlay{display:none!important}

/* 스크롤 바 고정 */
html {
  overflow-y: scroll;
}